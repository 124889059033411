// For Production
// export const apiBaseUrl = "https://smsvendor.com/public/api/v3";
// export const imageBaseUrl = "https://smsvendor.com/public/avatar";
export const apiBaseUrl = "https://admin.easy.com.np/public/api/v3"
export const imageBaseUrl = "https://admin.easy.com.np/public/avatar";


// // For Development
// export const apiBaseUrl = "http://localhost:8000/api/v3";
// export const imageBaseUrl = "http://localhost:8000/avatar";
